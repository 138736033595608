import React from "react";
import { CertificationsData } from "../../data/CertificationsData";
import Certification from "./Certification";

const Certifications = () => {
  const ROWS_TO_DISPLAY = 1;
  const data = CertificationsData;

  return (
    <div id={"certifications"} className="content rows ">
      <p
        style={{
          fontSize: "1.8rem",
          fontWeight: "600",
          color: "#545e69",
          paddingTop: "2.0rem",
        }}
      >
        Certifications
      </p>
      {data.map((item) => (
        <Certification
          image={item.image}
          title={item.title}
          items={item.items}
          count={ROWS_TO_DISPLAY}
        />
      ))}
    </div>
  );
};

export default Certifications;
