import React from "react";
import { HighlightsData } from "../../data/HighlightsData";
import PaginateComponent from "../project-template/PaginateComponent";

const itemsPerPage = 3;
const Talks = () => {
  const data = HighlightsData;
  // const talksData = data.filter((d) => d.activityType === "speak");
  const talksData = data.filter((d) => d.activityType === "speak" || d.activityType === "podcast");

  console.log("Talks data", talksData);
  return (
    <>
      <div id={"speak"} style={{ paddingTop: "1.0rem" }}>
        <p
          style={{
            fontSize: "1.8rem",
            fontWeight: "600",
            paddingBottom: "2.0rem",
            color: "#545e69",
          }}
        >
          Speaking Engagements
        </p>
        <PaginateComponent data={talksData} itemsPerPage={itemsPerPage} />
      </div>
    </>
  );
};
export default Talks;
