import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

const Certification = (props) => {
  const diamondSymbol = ` \u25C6 `;

  const [itemsToShow, setItemsToShow] = useState(props.count);

  const showMore = () => {
    setItemsToShow(props.items.length);
  };

  const showLess = () => {
    setItemsToShow(props.count);
  };

  return (
    <div className={"card row m-2 is-shadowless"}>
      <article className="media" style={{ margin: "1.5rem 1.5rem 0 0 " }}>
        {/*<figure className="media-left">*/}
        <p className="image is-64x64" style={{ margin: "1.5rem 1.5rem 0 0 " }}>
          <img src={props.image} alt={"certification"} />
        </p>
        {/*</figure>*/}
        <div className="media-content">
          <div className="content">
            <h4>{props.title}</h4>
          </div>

          {props.items.slice(0, itemsToShow).map((item, index) => (
            <article className="media">
              <div className="content" style={{ overflow: "hidden" }}>
                <strong style={{ color: "#545e69" }}>{item.name}</strong>
                {item.issuedBy ? (
                  <>
                    <br />
                    <span style={{ color: "black", fontSize: "small" }}>
                      {item.issuedBy}
                    </span>
                  </>
                ) : null}
                <br />
                <span style={{ color: "#778492", fontSize: "small" }}>
                  {item.issueDate}
                </span>{" "}
                {diamondSymbol}
                <i style={{ color: "black", fontSize: "small" }}>
                  No Expiration Date
                </i>
                {item.credentialID ? (
                  <span style={{ color: "black", fontSize: "small" }}>
                    <br /> <b>Credential ID: </b>
                    {item.credentialID}
                  </span>
                ) : null}
                {item.url ? (
                  <span>
                    <br />
                    <FontAwesomeIcon
                      icon={faExternalLinkAlt}
                      size={"1x"}
                      style={{ color: "#ea4f4b" }}
                    />{" "}
                    &nbsp;
                    <a href={item.url} style={{ fontSize: "small" }}>
                      View Certificate
                    </a>
                  </span>
                ) : null}
              </div>
            </article>
          ))}

          {props.items.length > 1 ? (
            itemsToShow === props.count ? (
              <button
                className="button is-primary is-light  is-right is-small"
                onClick={showMore}
                type="submit"
              >
                Show all {props.items.length} certifications
              </button>
            ) : (
              <button
                className="button is-light  is-right is-small"
                onClick={showLess}
              >
                Show Less
              </button>
            )
          ) : null}
        </div>
      </article>
    </div>
  );
};
export default Certification;
