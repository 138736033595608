import Telekom from "../assets/images/telekom.png";
import Experteer from "../assets/images/exp.png";
import Reinvent from "../assets/images/reinvent.jpeg";
import MaxPlanck from "../assets/images/mpi-2.png";
import Fortiss from "../assets/images/fortiss1.png";
import ISI from "../assets/images/isi.png";
import Roboy from "../assets/images/roboy.jpeg";
import TUM from "../assets/images/tum.jpeg";

export const ProfExperienceData = [
  {
    image: TUM,
    title:
      '<span style="color: black;font-weight: bold; font-size: larger"> Doctoral Candidate </span>',
    duration: "September 2022 - present",
    company: [
      {
        url: "https://tum.de/",
        location: "Munich, Germany",
        employmentType: "Full-time",
        name: "Technical University Munich",
      },
    ],
    multiPositions: [
      {
        name: "Doctoral Candidate @ ",
        company: "Chair of Connected Mobility",
        duration: "September 2022 - present",
        desc: [
          {
            para: "\u2022 Supervised by Prof. Dr.-Ing. Jörg Ott, mentored by" +
              " Dr." +
              " Wolfgang Wörndl",
          },
          {
            para: "\u2022 Research focussing on Recommender Systems and" +
              " Human-Computer Interaction, particularly in the tourism" +
              " domain.",
          },
        ],
      },

      {
        name: "Graduate Teaching Assistant @ ",
        company: "Chair of Connected Mobility",
        duration: "September 2022 - present",
        desc: [
          {
            para: "\u2022 Seminar: Topics in Recommender" +
              " Systems (WS22, WS23)",
          },
          {
            para: "\u2022 Lab Course: Hands-on Recommender" +
              " Systems" +
              " (SS23, SS24, WS24)",
          },
        ],
      },
    ],
  },
  {
    image: Telekom,
    title:
      '<span style="color: black;font-weight: bold; font-size: larger"> Data Scientist </span>',
    duration: "July 2020 - August 2022",
    company: [
      {
        url: "https://telekom.de/",
        location: "Bonn, Germany",
        employmentType: "Full-time",
        name: "Deutsche Telekom AG",
      },
    ],
    multiPositions: [
      {
        name: "Data Scientist:",
        company: "Deutsche Telekom Außendienst GmbH",
        duration: "February 2022 - August 2022",
        desc: [
          {
            para: "Developing and implementing Machine Learning use-cases in the Außendienst context.",
          },
        ],
      },

      {
        name: "Start up! Trainee:",
        company: "Technology & Innovation (VTI)",
        duration: "July 2020 - January 2022",
        desc: [
          { para: "\u2022 Data Science @ Network Analytics & Big Data, VTI" },
          { para: "\u2022 Software Development @ Magenta Voice Program" },
          {
            para: "\u2022 Data Engineering @ DT Technik (Smart Operations/SMOPS)",
          },
          {
            para: "\u2022 Lean Innovation Scholarship to realize the product idea of a low-cost bicycle tracker.",
          },
          { para: "\u2022 Data Engineering @ DT-IT (IPTV Team)" },
          {
            para: "\u2022 Data Science @ DT Service (Chapter Predictive Maintenance & KI)",
          },
        ],
      },
    ],
  },
  {
    image: Experteer,
    title:
      '<span style="color: black;font-weight: bold; font-size: larger"> Software Developer: Machine Learning</span>',
    company: [
      {
        name: "Experteer Gmbh",
        url: "https://www.experteer.de/",
        location: "Munich, Germany",
        employmentType: "Full-time",
      },
    ],
    duration: "November 2019 - May 2020",
    desc: [
      {
        para: "Technologies: Python, SQL, MongoDB, Ruby on Rails, Elastic Search, Kibana, RabbitMQ",
      },
      {
        para: "I worked mostly in the NLP domain, using Machine Learning models to extract useful information from the job content.",
      },
    ],
  },
  {
    image: Reinvent,
    title:
      '<span style="color: black;font-weight: bold; font-size: larger"> Working Student: Backend Development</span>',
    company: [
      {
        name: "reINVENT Gmbh",
        url: "https://re-invent.de/",
        location: "Munich, Germany",
        employmentType: "Part-time",
      },
    ],
    duration: "May 2019 - October 2019",
    desc: [
      { para: "Technologies: Git, JavaScript, Node.JS, KOA, Sequelize" },
      {
        para: "My tasks included developing the backend for their software using the aforementioned technologies.",
      },
    ],
  },
  {
    image: MaxPlanck,
    title:
      '<span style="color: black;font-weight: bold; font-size: larger"> Masters Thesis/ Internship: Networked Systems</span>',
    company: [
      {
        url: "https://www.mpi-sws.org/",
        location: "Saarbrücken, Germany",
        employmentType: "Internship",
        name: "Max Planck Institute for Software Systems",
      },
    ],
    duration: "March 2018 - July 2019",
    multiPositions: [
      {
        name: "Masters Thesis/ Internship: Networked Systems",
        // company: "Max Planck Institute of Software Systems",
        duration: "November 2018 - July 2019",
        desc: [
          { para: "Advisor: Dr Krishna P. Gummadi" },
          {
            para: "Thesis Title: Untersuchung von Verserrungseffekten in ortsbezogener suche/ Analyzing Exposure Bias in Location-based Search",
          },
          {
            para: "Technologies: Python, Selenium, BeautifulSoup, Scikit-learn, Matplotlib, Seaborn, Jupyter, Information Retrieval, Data Mining, Web-scraping",
          },
        ],
      },

      {
        name: "Visiting Research Scholar (Student): Networked Systems",
        // company: "Max Planck Institute of Software Systems",
        duration: "March 2018 - May 2018",
        desc: [
          { para: "Advisor: Dr Krishna P. Gummadi" },

          {
            para: "Technologies: Python, Machine Learning (Frequent Itemset Mining, Logistic Regression), Data Analysis, Information Retrieval",
          },
          {
            para: "The top-level goal of our project was to detect the advent of a malicious advertiser on an online advertising platform. We characterized discrimination and proposed mechanisms for detecting whether an ad is discriminatory as well as mitigation by expanding the ad’s targeted audience to include under-represented groups. The analysis was carried out using several data analytic and machine learning strategies on a real-world dataset before arriving at a robust solution, which was able to detect and mitigate discrimination against certain groups of people on an online targeted advertising platform.",
          },
        ],
      },
    ],
  },
  {
    image: Fortiss,
    title:
      '<span style="color: black;font-weight: bold; font-size: larger"> Working Student: Model-based Security Engineering </span>',
    company: [
      {
        name: "Fortiss: Landesforschungsinstitut des Freistaats Bayern",
        url: "https://www.fortiss.org/",
        location: "Munich, Germany",
        employmentType: "Part-time",
      },
    ],

    duration: "November 2017 - September 2018",
    desc: [
      { para: "Supervisor: Dr. Vivek Nigam" },
      {
        para: "Technologies: Java, UI development, Object-oriented programming, Attack Defense Trees, Computer Security",
      },
      {
        para: "My tasks involved mostly Dependibility Assessment; security analysis of the safety patterns and modelling the safety case arguments as automated Attack Defense Trees in their OpenSource project AUTOFOCUS-3 (AF3).",
      },
    ],
  },
  {
    image: Roboy,
    title:
      '<span style="color: black;font-weight: bold; font-size: larger"> Roboy: Student Coordinator</span>',
    company: [
      {
        name: "Roboy",
        url: "https://www.linkedin.com/company/devanthro/",
        location: "Munich",
        employmentType: "Part-time",
      },
    ],

    duration: "April 2017 - August 2017",
    desc: [
      {
        para: "As a student coordinator at Roboy, TUM, I was in charge of the managing Roboy's collaborations and coordinating the activities of the team.",
      },
    ],
  },
  {
    image: ISI,
    title:
      '<span style="color: black;font-weight: bold; font-size: larger"> Summer Research Intern: Stream Ciphers & Cryptology</span>',
    company: [
      {
        name: "Indian Statistical Institute",
        url: "https://www.isical.ac.in/",
        location: "Kolkata",
        employmentType: "Full-time",
      },
    ],

    duration: "June 2015 - July 2015",
    desc: [
      { para: "Mainly worked in public-key cryptography." },
      { para: "Studied the stream ciphers like Salsa and Chacha." },
      {
        para: "Implemented the above using C programming language.",
      },
    ],
  },
];
