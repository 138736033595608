import React, { useState } from "react";
import "./skills.css";
import { SkillsData } from "../../data/SkillsData";

const NUMBER_OF_SKILLS_TO_SHOW = 18;
const TechSkills = () => {
  const data = SkillsData;
  const [itemsToShow, setItemsToShow] = useState(NUMBER_OF_SKILLS_TO_SHOW);

  const showMore = () => {
    setItemsToShow(data.length);
  };

  const showLess = () => {
    setItemsToShow(NUMBER_OF_SKILLS_TO_SHOW);
  };
  const PER_CHUNK = 6;
  const result = data
    .slice(0, itemsToShow)
    .reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / PER_CHUNK);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }

      resultArray[chunkIndex].push(item);

      return resultArray;
    }, []);

  return (
    <>
      <div
        id={"tech-skills"}
        className="content rows"
        style={{ background: "white" }}
      >
        <p
          style={{
            fontSize: "1.8rem",
            fontWeight: "600",
            color: "#545e69",
            paddingTop: "2.0rem",
            paddingBottom: "1.5rem",
          }}
        >
          Technical
        </p>
        <div
          className="skillset container "
          style={{ justifyContent: "center" }}
        >
          {result.map((chunk, index) => (
            <div className={"level level-item-skill"}>
              {chunk.map((skill, i) => (
                <div className="level-item has-text-justified level-item-skill">
                  <span className="image is-48x48  skills-img">
                    <img
                      src={skill.image}
                      alt={skill.name}
                      title={skill.name}
                    />
                  </span>
                </div>
              ))}
            </div>
          ))}
          {itemsToShow === NUMBER_OF_SKILLS_TO_SHOW ? (
            <button
              className="button  is-primary is-light is-centered is-fullwidth show-more-button is-small"
              onClick={showMore}
              type="submit"
            >
              Show all {data.length} Technical Skills
            </button>
          ) : (
            <button
              className="button is-light is-centered is-fullwidth is-small"
              onClick={showLess}
            >
              Show Less
            </button>
          )}
        </div>
      </div>
    </>
  );
};
export default TechSkills;
