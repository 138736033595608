import Python from "../assets/images/skills/python.jpeg";
import Javascript from "../assets/images/skills/javascript.jpeg";
import Tex from "../assets/images/skills/latex.png";
import HTML from "../assets/images/skills/html.png";
import CSS from "../assets/images/skills/css.png";
import ReactImg from "../assets/images/skills/react.png";
import Django from "../assets/images/skills/django.png";
import Firebase from "../assets/images/skills/firebase.png";
import Mongo from "../assets/images/skills/mongo.png";
import PostGre from "../assets/images/skills/postgres.png";
import Jupyter from "../assets/images/skills/jupyter.png";
import Pandas from "../assets/images/skills/pandas.png";
import Numpy from "../assets/images/skills/numpy.png";
import Linux from "../assets/images/skills/linux.jpeg";
import Kibana from "../assets/images/skills/kibana.png";
import Scikit from "../assets/images/skills/scikit.png";
import Matplotlib from "../assets/images/skills/matplotlib.png";
import RabbitMQ from "../assets/images/skills/rabbitMQ.png";
import Ruby from "../assets/images/skills/ruby.png";
import Seaborn from "../assets/images/skills/seaborn.jpeg";
import Hive from "../assets/images/skills/hive.png";
import ElasticSearch from "../assets/images/skills/elastic.png";
import Git from "../assets/images/skills/git.png";
import Docker from "../assets/images/skills/docker.png";
import Scipy from "../assets/images/skills/scipy.png";
import Cloudera from "../assets/images/skills/cloudera.png";
import Plotly from "../assets/images/skills/plotly.png";
import FastAPI from "../assets/images/skills/fastAPI.png";
import Pyspark from "../assets/images/skills/pyspark.jpeg";

export const SkillsData = [
  {
    name: "Python",
    url: "",
    image: Python,
  },
  {
    name: "Ruby",
    url: "",
    image: Ruby,
  },
  {
    name: "Javascript",
    url: "",
    image: Javascript,
  },
  {
    name: "HTML",
    url: "",
    image: HTML,
  },
  {
    name: "CSS",
    url: "",
    image: CSS,
  },
  {
    name: "React JS",
    url: "",
    image: ReactImg,
  },
  {
    name: "Django",
    url: "",
    image: Django,
  },
  {
    name: "FastAPI",
    url: "",
    image: FastAPI,
  },
  {
    name: "Firebase",
    url: "",
    image: Firebase,
  },
  {
    name: "MongoDB",
    url: "",
    image: Mongo,
  },
  {
    name: "PostGreSQL",
    url: "",
    image: PostGre,
  },
  {
    name: "PySpark",
    url: "",
    image: Pyspark,
  },
  {
    name: "Jupyter Notebook",
    url: "",
    image: Jupyter,
  },
  {
    name: "Numpy",
    url: "",
    image: Numpy,
  },
  {
    name: "Pandas",
    url: "",
    image: Pandas,
  },
  {
    name: "Scikit",
    url: "",
    image: Scikit,
  },
  {
    name: "Scipy",
    url: "",
    image: Scipy,
  },
  {
    name: "Matplotlib",
    url: "",
    image: Matplotlib,
  },
  {
    name: "Seaborn",
    url: "",
    image: Seaborn,
  },
  {
    name: "Plotly",
    url: "",
    image: Plotly,
  },
  {
    name: "Cloudera Workbench",
    url: "",
    image: Cloudera,
  },
  {
    name: "Hive",
    url: "",
    image: Hive,
  },
  {
    name: "Elastic Search",
    url: "",
    image: ElasticSearch,
  },
  {
    name: "Kibana",
    url: "",
    image: Kibana,
  },
  {
    name: "RabbitMQ",
    url: "",
    image: RabbitMQ,
  },
  {
    name: "Docker",
    url: "",
    image: Docker,
  },
  {
    name: "Git",
    url: "",
    image: Git,
  },
  {
    name: "TeX",
    url: "",
    image: Tex,
  },
  {
    name: "Linux",
    url: "",
    image: Linux,
  },
];
