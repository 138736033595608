export const CvPageHeaderData = [
  { url: "/", text: "Home" },

  {
    text: "Background",
    dropDown: [
      { text: "Experiences", url: "/cv/#experiences" },
      { text: "Education", url: "/cv/#education" },
      // {
      //     text: "Publications",
      //     url: "/cv/#publications",
      // },
    ],
  },
  // { url: "/cv/#publications", text: "Publications" },

  {
    text: "Skills",
    url: "/cv/#skills",
    dropDown: [
      { text: "Technical", url: "/cv/#tech-skills" },
      { text: "Certifications", url: "/cv/#certifications" },
      { text: "Linguistic", url: "/cv/#languages" },
    ],
  },
  {
    text: "Miscellaneous",
    url: "/cv/#miscellaneous",
    dropDown: [
      { text: "Awards", url: "/cv/#awards" },
      { text: "Volunteering", url: "/cv/#volunteering" },
      { text: "Features", url: "/cv/#features" },
    ],
  },
  {
    text: "Previously",
    url: "/cv/#previously",
  },
];
