import React from "react";
import { HashLink } from "react-router-hash-link";
import "../components/about-me/person.scss";



const Header = (props) => {
  function handleBurgerClick(event) {
    event.target.classList.toggle("is-active");
    document.querySelector(".navbar-menu").classList.toggle("is-active");
  }
  // Media queries for padding on big screens
  // const bigScreenPadding = {
  //   '@media screen and (min-width: 1200px)': {
  //     paddingLeft: '10rem'  // Adjust padding as needed
  //   },
  // };
  //
  // const navItemsBigScreenPadding = {
  //   '@media screen and (min-width: 1200px)': {
  //     paddingRight: '100rem'
  //   },
  // }
  return (
    <nav
      className="navbar is-fixed-top"
      role="navigation"
      aria-label="main navigation"
      style={{ background: "whitesmoke" }}
    >
      <div className={"centeredContainer"} >
        <a
          role="button"
          className="navbar-burger burger"
          onClick={handleBurgerClick}
          href={"/#"} // add this for accessibility!
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarMenu"
        >
          <span aria-hidden="true" />
          <span aria-hidden="true" />
          <span aria-hidden="true" />
        </a>
        <div
          id="navbar-menu"
          className="navbar-menu"
          onClick={handleBurgerClick}
          style={{paddingTop:"1.5rem"}}
        >
          <div className={"navbar-start"} >
            <a href={"/"} className={"ab"}>
              {"Ashmi Banerjee "}
            </a>
          </div>

          <div className="navbar-end big-screen-padding" >
            {props.items.map((item) => {
              if (item.dropDown)
                return (
                  <div className="navbar-item has-dropdown is-hoverable">
                    <a className="navbar-link">{item.text}</a>

                    <div className={"navbar-dropdown"}>
                      {item.dropDown.map((i, j) => (
                        <>
                          <HashLink
                            className="navbar-item"
                            to={i.url}
                            smooth="true"
                          >
                            {i.text}
                          </HashLink>
                        </>
                      ))}
                    </div>
                  </div>
                );
              else
                return (
                  <HashLink className="navbar-item" to={item.url} smooth="true">
                    {item.text}
                  </HashLink>
                );
            })}
          </div>
        </div>
      </div>
    </nav>
  );
};
export default Header;
