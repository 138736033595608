import React from "react";
import { useGetMediumBlogs } from "../../backend-connections/GetMediumBlogs";
import PaginateComponent from "../project-template/PaginateComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import "../project-template/projectTemplate.css";

const ITEMS_TO_SHOW = 2;
const BlogsComponent = () => {
  const data = useGetMediumBlogs();
  console.log("blogs data length: ", data.length);
  data.items.forEach((row, index) => {
    row.activityType = "blog";
  });
  if (data.isLoading) return <div> Blogs loading</div>;
  else
    return (
      <div id={"blogs"}>
        <div style={{ paddingBottom: "2.0rem" }}>
          <p
            style={{ fontSize: "1.8rem", fontWeight: "600", color: "#545e69" }}
          >
            Recent Blogs
          </p>
          <span className={"link-reverse"}>
            <br />
            <FontAwesomeIcon icon={faExternalLinkAlt} size={"1x"} /> &nbsp;
            <a
              href={"https://medium.com/@ashmi_banerjee"}
              className={"link-reverse"}
              style={{ fontStyle: "italic" }}
            >
              View All Blogs.
            </a>
          </span>
        </div>
        <PaginateComponent data={data.items} itemsPerPage={ITEMS_TO_SHOW} />
      </div>
    );
};
export default BlogsComponent;
