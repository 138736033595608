import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../about-me/person.scss";
import {
  SizeProp,
  IconPrefix,
  IconName,
} from "@fortawesome/fontawesome-svg-core";


type SocialItem = {
  id: string;
  title: string;
  // icon: typeof FontAwesomeIcon
  icon: [IconPrefix, IconName];
  color?: string;
  href: string;
};

type Props = {
  items: SocialItem[];
  iconSize: SizeProp;
};

const containerStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "row",
  flexWrap: 'nowrap',
  maxWidth: "10%",
  justifyContent: "center", // Center items horizontally
  paddingTop: "1rem", // Add some padding around the container
  // paddingLeft: "4.5rem",
  boxSizing: "border-box",
};

const getLevelItemStyle = (isLastChild: boolean, screenSize: number): React.CSSProperties => {
  const baseStyle: React.CSSProperties = {
    flexGrow: 1, // Allow items to grow to fill the container
    flexBasis: "auto", // Basis auto to allow natural sizing based on content
    display: "flex",
    justifyContent: "center", // Center the icon within the item
    margin: "0.5rem", // Add margin to ensure space between icons
  };

  if (screenSize <= 600) {
    baseStyle.marginBottom = "0.75rem"; // Add more bottom margin on small screens
    baseStyle.flex = "0 0 25%"; // Limit item width to 25% to ensure wrapping
    baseStyle.maxWidth = "100%";
  }
  if (screenSize > 600) {
    // baseStyle.marginLeft = "5rem";
    // baseStyle.maxWidth = "10%";
  }

  return baseStyle;
};



const iconStyle: React.CSSProperties = {
  color: "black",
};

const getContainerStyle = (screenSize: number): React.CSSProperties => {
  const baseStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",
    flexWrap: 'nowrap',
    maxWidth: "10%",
    justifyContent: "center", // Center items horizontally
    paddingTop: "1rem", // Add some padding around the container
    boxSizing: "border-box",
  };

  if (screenSize > 600 && screenSize < 1200){
    baseStyle.paddingLeft = "4rem";
  }
  if (screenSize > 1200){
    baseStyle.paddingLeft = "4.5rem";
  }

  return baseStyle;
};

const Socials: React.FC<Props> = ({ items, iconSize }) => {
  const isSmallScreen = window.innerWidth <= 768;
  const isMediumScreen = window.innerWidth >= 768 && window.innerWidth <= 992;
  const screenSize = window.innerWidth
  return (
    <div style={getContainerStyle(screenSize)} className={"level"} >
      {items.map((item, index) => (
        <a
          key={item.id}
          style={getLevelItemStyle(index === items.length - 1, screenSize)}
          href={item.href}
        >
          <FontAwesomeIcon
            icon={item.icon}
            size={iconSize}
            title={item.title}
            style={iconStyle}
          />{""}
        </a>
      ))}
    </div>
  );
};
export default Socials;
