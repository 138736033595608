import React from "react";
import "./publication.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons";

const Publication = (props) => {
  function createMarkup(data) {
    return { __html: data };
  }
  return (
    <div className="item">
      <ul>
       <li>
         <a  href={props.paperUrl} className={"publication-title-link-hover"}><b>
           {props.paperTitle}</b></a>
        <p>
        <span dangerouslySetInnerHTML={createMarkup(props.authorNames)}/>,
            <a href={props.venue[0].url} style={{color:"#3e8ed0ff"}}>
              &nbsp;{props.venue[0].name}</a>
            &nbsp;{props.venue[0].text}
          </p>
          {props.paperUrl ? (
            <span className={"tag is-primary is-normal"}>
              <a
                href={props.paperUrl}
                style={{ color: "white", fontSize: "small" }}
              >
                <FontAwesomeIcon icon={faFilePdf} size={"1x"} />
                &nbsp; PDF
              </a>
            </span>
          ) : null}
          {props.bibTeX ? (
            <>
              &nbsp;&nbsp;
              <span className={"tag is-info is-normal"}>
                <a
                  href={props.bibTeX}
                  style={{ color: "white", fontSize: "small" }}
                >
                  <FontAwesomeIcon icon={faQuoteLeft} size={"1x"} />
                  &nbsp; BibTeX
                </a>
              </span>
            </>
          ) : null}
        </li>
      </ul>
    </div>
  );
};

export default Publication;
