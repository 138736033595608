import AnitaBorg from "../assets/images/anitaBorg.jpeg";
import WTM from "../assets/images/wtm.jpeg";
import TUM from "../assets/images/tum.jpeg";
import MPI_SWS from "../assets/images/mpi-2.png";
import MPI from "../assets/images/mpi-g.png";
import Heritage from "../assets/images/heritage.jpg";
import Telegraph from "../assets/images/telegraph.png";
import TCS from "../assets/images/tcs.svg";
import Nasscom from "../assets/images/nasscom.jpeg";
import DevelopHER from "../assets/images/developHERlogo.png";
import WWCApplaudHerLogo from "../assets/images/applaudher-logo.png";
import WWCodeLogo from "../assets/images/talks/logos/WWC.jpeg";
import GoogleLogo from "../assets/images/google.jpeg";
import GDEAward from "../assets/documents/GDE_Award.pdf"
import TCSAward from "../assets/documents/tcs-best-student.pdf"
import NassComAward from "../assets/documents/nasscom.pdf"

export const ScholasticAchievementsData = [
  {
    image: GoogleLogo,
    duration: "2023",
    title: "Google" +
      " Developer Expert" +
      " Community Award (Rising" +
      " Star)",
    company: [
      {
        name: "Google",
        url: GDEAward,
        location: "Amsterdam, Netherlands",
      },
    ],
    desc: [
      {
        para: "Awarded the <a href='https://ashmibanerjee.com/static/media/GDE_Award.d7a642f60608cdce2850.pdf'>Google Developer Expert Community Award</a> in the <b>Rising Star</b> category in recognition of her contributions to the developer community for the year 2022-23.",
      },
    ],
  },
  {
    image: WWCodeLogo,
    duration: "2023",
    title: "2023 WWCode Applaud Her Award (Data Science)",
    company: [
      {
        name: "Women Who Code",
        url: "https://www.credential.net/77351a77-aa69-4835-8310-355d8828b701#gs.3blbau",
        location: "Global",
      },
    ],
    desc: [
      {
        para: "Awarded the <a>2023 WWCode Applaud Her Award - Data Science</a> by Women Who Code, acknowledging her dedication towards bridging the gender-gap in STEM.",
      },
    ],
  },

  {
    image: WWCApplaudHerLogo,
    duration: "2023",
    title: "Women Who Code Applaud Her Awards: 100 Technologists to Watch",
    company: [
      {
        name: "Women Who Code",
        url: "https://www.womenwhocode.com/100-technologists-to-watch?mc_cid=4dd7b3516a&mc_eid=99ae716a8c",
        location: "Global",
      },
    ],
    desc: [
      {
        para: "Recognised as one of the 100 technologists globally for her contributions to tech.",
      },
    ],
  },
  {
    image: DevelopHER,
    duration: "2022",
    title: "DevelopHER Award 2022 (Emerging Talent)",
    company: [
      {
        name: "DevelopHER",
        url: "https://developher.co.uk/developher-winners-2022/",
        location: "Norwich, UK",
      },
    ],
    certificate: "https://developher.co.uk/developher-winners-2022/",
    desc: [
      {
        para: "Awarded with the DevelopHER Awards in the Emerging Talent Category by DevelopHER UK.",
      },
      {
        para: "Founded in 2013 by Vickie Allen, DevelopHER exists to shine a light on incredible women working in the industry and to provide advice and support to anyone that needs it.",
      },
    ],
  },
  {
    image: AnitaBorg,
    duration: "2019",
    title: "Grace Hopper Down Under Student Scholarship 2019",
    company: [
      {
        name: "Anita Borg",
        url: "https://anitab.org/",
        location: "Brisbane, Australia",
      },
    ],

    desc: [
      {
        para:
          "Received the scholarship to attend the 2019 Hopper Down\n" +
          "Under celebrations, Brisbane, Australia. However, could not attend due to logistical challenge.",
      },
    ],
  },
  {
    image: WTM,
    duration: "2018",
    title: "Google WTM & Firebase Travel Grant 2018",
    company: [
      {
        name: "Google WomenTechmakers",
        url: "https://www.womentechmakers.com/",
        location: "Prague, Czech Republic",
      },
    ],

    desc: [
      {
        para: "Received the Google WomenTechmakers and Firebase Travel Grant to attend the Google Firebase Summit 2018 in Prague, Czech Republic.",
      },
    ],
  },
  {
    image: TUM,
    duration: "2018",
    title: " IFF (Informatik-Forum Frauen) Gender & Diversity Grant 2018",
    company: [
      {
        name: "Technical University Munich",
        url: "https://www.tum.de/",
        location: "Munich, Germany",
      },
    ],

    desc: [
      {
        para: "Received the IFF travel grant to attend the European Women in Technology Conference 2018 in Amsterdam, Netherlands.",
      },
    ],
  },
  {
    image: MPI_SWS,
    duration: "2018",
    title: "Max Planck Visiting Scholar Fellowship 2018",
    company: [
      {
        name: "Max Planck Institute for Software Systems (MPI-SWS)",
        url: "https://www.mpi-sws.org/",
        location: "Saarbrücken, Germany",
      },
    ],

    desc: [
      {
        para: "Was awarded the fellowship to conduct research at MPI-SWS between March 2018 - May 2018 under the supervision of Dr. Krishna P. Gummadi.",
      },
    ],
  },
  {
    image: MPI,
    duration: "2017",
    title:
      "The Cornell, Maryland, Max Planck Pre-doctoral Research School (CMMRS) 2017",
    company: [
      {
        name: "Max Planck Institute of Software Systems (MPI-SWS)",
        url: "https://www.mpi-sws.org/",
        location: "Saarbrücken, Germany",
      },
    ],

    desc: [
      {
        para: "Selected among the top 50 candidates among 1000 applicants for attending an all-expenses covered week-long summer school organized by MPI-SWS and Cornell University, US at MPI-SWS, Saarbruecken, Germany.",
      },
    ],
  },
  {
    image: Heritage,
    duration: "2016",
    title: "Best Overall Performance (B.Tech) Award 2016",
    company: [
      {
        name: "Heritage Institute of Technology",
        url: "https://heritage.edu/",
        location: "Kolkata, India",
      },
    ],

    desc: [
      {
        para: "Awarded the Best Overall Performance Award 2016 from Heritage Institute of Technology (all departments combined) in recognition of the academic and extra-curricular excellence as adjudged by the institute.",
      },
    ],
  },
  {
    image: Telegraph,
    duration: "2016",
    title: "The Telegraph You Scholarship Finalist 2016",
    company: [
      {
        name: "The Telegraph",
        url: "https://www.telegraphindia.com/education/you-scholarship/cid/1441077",
        location: "Kolkata, India",
      },
    ],

    desc: [
      {
        para: "Finalist (top 5) for The Telegraph YOU Scholarship which is awarded to 2 students every year for pursuing postgraduate education from universities outside India.",
      },
    ],
  },
  {
    image: TCS,
    duration: "2016",
    title: " Best Student Award 2016",
    company: [
      {
        name: "Tata Consultancy Services",
        url: TCSAward,
        location: "India",
      },
    ],

    desc: [
      {
        para: "Awarded the Best Student Award 2016 from Heritage Institute of Technology, in recognition of the academic excellence, as adjudged by the institute. Awards are presented to best students of 100 high-ranking engineering institutes across India.",
      },
    ],
  },
  {
    image: Nasscom,
    duration: "2016",
    title: "Dewang Mehta Excellence Award 2016",
    company: [
      {
        name: "National Association of Software and Systems Company (NASSCOM)",
        url: NassComAward,
        location: "India",
      },
    ],

    desc: [
      {
        para: "Felicitated by NASSCOM for being among the top 3 students of the department (Top Ranker's Award).",
      },
    ],
  },
];
