import TUM from "../assets/images/tum.jpeg";

export const TeachingData = [
  {
    duration: "Winter 2023/24",
    thumbnail: TUM,
    activityType: "teach",
    organisation: "[WS 2023] Topics in Recommender Systems",
    url: "https://www.ce.cit.tum.de/cm/teaching/seminar-topics-in-recommender-systems-ws-2023-24-in2107-in4945/",
    desc: '<span style="word-break: break-word;">Teaching Assistant for the Seminar <b style="color:#778492;">Topics in Recommender Systems </b> based on the chapters of the  <a href=\'https://link.springer.com/book/10.1007/978-1-0716-2197-4\' style="color:#ea4f4b; ">Recommenders System Handbook</a> conducted by the <a href=\'https://www.ce.cit.tum.de/cm/home/\'>Chair of Connected Mobility</a>, TU Munich.</span>',
    links: [
      {
        url: "https://www.ce.cit.tum.de/cm/teaching/seminar-topics-in-recommender-systems-ws-2022-23-in2107-in4945/",
        text: "Link",
        icon: "fas fa-link",
        tagColor: "is-info",
      },
      {
        url: "https://link.springer.com/book/10.1007/978-1-0716-2197-4",
        text: "Resources",
        icon: "fas fa-book-open",
        tagColor: "is-warning",
      },
    ],
  },
  {
    duration: "Summer 2023",
    thumbnail: TUM,
    activityType: "teach",
    organisation: "[SS 2023] Lab Course: Hands-on Recommender Systems",
    url: "https://www.ce.cit.tum.de/cm/teaching/summer-term-2023/lab-course-hands-on-recommender-systems/",
    desc: '<span style="word-break: break-word;">Teaching Assistant for the Lab Course <b style="color:#778492;">Hands-on Recommender Systems</b> conducted by the <a href=\'https://www.ce.cit.tum.de/cm/home/\'>Chair of Connected Mobility</a>, TU Munich.</span>',
    links: [
      {
        url: "https://www.ce.cit.tum.de/cm/teaching/summer-term-2023/lab-course-hands-on-recommender-systems/",
        text: "Link",
        icon: "fas fa-link",
        tagColor: "is-info",
      },
    ],
  },
  {
    duration: "Winter 2022/23",
    thumbnail: TUM,
    activityType: "teach",
    organisation: "[WS 2022] Topics in Recommender Systems",
    url: "https://www.ce.cit.tum.de/cm/teaching/seminar-topics-in-recommender-systems-ws-2022-23-in2107-in4945/",
    desc: '<span style="word-break: break-word;">Teaching Assistant for the Seminar <b style="color:#778492;">Topics in Recommender Systems </b> based on the chapters of the  <a href=\'https://link.springer.com/book/10.1007/978-1-0716-2197-4\' style="color:#ea4f4b; ">Recommenders System Handbook</a> conducted by the <a href=\'https://www.ce.cit.tum.de/cm/home/\'>Chair of Connected Mobility</a>, TU Munich.</span>',
    links: [
      {
        url: "https://www.ce.cit.tum.de/cm/teaching/seminar-topics-in-recommender-systems-ws-2022-23-in2107-in4945/",
        text: "Link",
        icon: "fas fa-link",
        tagColor: "is-info",
      },
      {
        url: "https://link.springer.com/book/10.1007/978-1-0716-2197-4",
        text: "Resources",
        icon: "fas fa-book-open",
        tagColor: "is-warning",
      },
    ],
  },
];
