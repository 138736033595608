import { useEffect, useState } from "react";

const MEDIUM_URL =
  "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@ashmi_banerjee";

export function useGetMediumBlogs() {
  const [profile, setProfile] = useState({
    name: "Ashmi Banerjee",
    profileImage: "",
    profileUrl: "",
  });

  const [blogs, setBlogs] = useState({
    items: [],
    isLoading: true,
    error: null,
  });
  useEffect(() => {
    fetch(MEDIUM_URL)
      .then((res) => res.json())
      .then((info) => {
        const image = info.feed.image;
        const link = info.feed.link;
        const blogs = info.items;
        const posts = blogs.filter(
          (post: { categories: string | any[] }) => post.categories.length > 0,
        );
        setProfile((p) => ({ ...p, profileUrl: link, profileImage: image }));
        setBlogs({ error: null, items: posts, isLoading: false });
      })
      .catch((err) =>
        setBlogs({ isLoading: false, items: [], error: err.message }),
      );
  }, []);
  // console.log("blogs", blogs)
  return blogs;
}
