import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TechSkills from "./TechSkills";
import Certifications from "../certifications/Certifications";
import Languages from "../languages/Languages";

const Skills = () => {
  const [activeTab, setActiveTab] = useState("tab1");
  const location = useLocation();
  const hash = location.hash;
  const navigate = useNavigate();

  const handleTab1 = () => {
    // update the state to tab1
    setActiveTab("tab1");
    navigate("/cv/#tech-skills");
  };
  const handleTab2 = () => {
    // update the state to tab2
    setActiveTab("tab2");
    navigate("/cv/#certifications");
  };
  const handleTab3 = () => {
    // update the state to tab2
    setActiveTab("tab3");
    navigate("/cv/#languages");
  };

  useEffect(() => {
    switch (hash) {
      case "#tech-skills":
        setActiveTab("tab1");
        break;
      case "#certifications":
        setActiveTab("tab2");
        break;
      case "#languages":
        setActiveTab("tab3");
        break;
    }
  }, [hash, setActiveTab]);
  return (
    <div id={"skills"}>
      <article className="is-light" style={{ background: "white" }}>
        <p className="title" style={{ color: "#0a478a", fontSize: "1.8rem" }}>
          Skills
        </p>
        <div className="tabs  is-fullwidth is-boxed">
          <ul>
            <li
              className={activeTab === "tab1" ? "is-active" : ""}
              onClick={handleTab1}
            >
              <a>
                <span
                  className={
                    "icon is-small " + (activeTab === "tab1" ? "is-active" : "")
                  }
                >
                  <i className="fas fa-microchip" aria-hidden="true"></i>
                </span>
                <span>Technical</span>
              </a>
            </li>
            <li
              className={activeTab === "tab2" ? "is-active" : ""}
              onClick={handleTab2}
            >
              <a>
                <span
                  className={
                    "icon is-small " + (activeTab === "tab2" ? "is-active" : "")
                  }
                  onClick={handleTab2}
                >
                  <i className="fas fa-certificate" aria-hidden="true"></i>
                </span>
                <span>Certifications</span>
              </a>
            </li>
            <li
              className={activeTab === "tab3" ? "is-active" : ""}
              onClick={handleTab3}
            >
              <a>
                <span
                  className={
                    "icon is-small " + (activeTab === "tab3" ? "is-active" : "")
                  }
                  onClick={handleTab3}
                >
                  <i className="fas fa-globe" aria-hidden="true"></i>
                </span>
                <span>Linguistics</span>
              </a>
            </li>
          </ul>
        </div>
        {(() => {
          if (activeTab === "tab1") {
            return <TechSkills />;
          } else if (activeTab === "tab2") {
            return <Certifications />;
          } else {
            return <Languages />;
          }
        })()}
      </article>
    </div>
  );
};

export default Skills;
