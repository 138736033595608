import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { HighlightsData } from "../../data/HighlightsData";
import "./paginateStyle.css";
import "../project-template/paginate.css";

function createMarkup(data) {
  return { __html: data };
}

function Items({ currentItems }) {
  return (
    <>
      <table className={"table"}>
        {currentItems.map((item) => (
          <tr>
            <td>
              <span style={{ color: "#363636" }}>
                {item.date ? item.date : item.year}
              </span>
            </td>
            <td>
              {item.descV2 ? (
                <div dangerouslySetInnerHTML={createMarkup(item.title)} />
              ) : (
                <div dangerouslySetInnerHTML={createMarkup(item.desc)} />
              )}

              {item.links
                ? item.links.map((link) => (
                    <>
                      <a
                        href={link.url}
                        style={{ color: "white", fontSize: "small" }}
                      >
                        <span className={`tag ${link.tagColor} is-normal`}>
                          <i
                            className={`${link.icon} fa-1x`}
                            style={{ color: "white" }}
                          />
                          &nbsp;&nbsp;
                          {link.text}
                        </span>{" "}
                        &nbsp;&nbsp;
                      </a>
                    </>
                  ))
                : null}
            </td>
          </tr>
        ))}
      </table>
    </>
  );
}
const itemsPerPage = 5;

const PreviousHighlights = () => {
  const allData = HighlightsData;
  const data = allData.filter((d) => d.upcoming !== true);

  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState(data);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(data.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / itemsPerPage));
  }, [itemOffset, itemsPerPage]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    console.log(
      `On Prev User requested page number ${event.selected}, which is offset ${newOffset}`,
    );
    console.log(`On prev length of items ${data.length}`);
    setItemOffset(newOffset);
  };

  if (data.length > 1) {
    console.log("itemOffset", itemOffset);
    console.log("itemOffset+itemsPerPage", itemOffset + itemsPerPage);
    console.log("prevH data length", data.length);
    console.log("all data length", allData.length);
    return (
      <>
        <Items currentItems={currentItems} />
        <p>
          <br />
        </p>
        <ReactPaginate
          breakLabel="..."
          nextLabel={
            itemOffset + itemsPerPage >= data.length ? (
              <div
                style={{
                  border: "none",
                  backgroundColor: "white",
                  color: "white",
                  display: "none",
                }}
              >
                j
              </div>
            ) : (
              <>&raquo;</>
            )
          }
          onPageChange={handlePageClick}
          pageRangeDisplayed={2}
          pageCount={pageCount}
          previousLabel={
            itemOffset === 0 ? (
              <div
                style={{
                  border: "1px solid white",
                  backgroundColor: "red",
                  display: "none",
                }}
              >
                h
              </div>
            ) : (
              <>&laquo;</>
            )
          }
          // renderOnZeroPageCount={null}
          renderOnZeroPageCount={() => (
            <div style={{ display: "none" }}>No more pages</div>
          )}
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination justify-content-center"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName={itemOffset === 0 ? "hide-label" : "page-item"}
          previousLinkClassName="page-link"
          nextClassName={
            itemOffset + itemsPerPage >= data.length
              ? "hide-label"
              : "page-item"
          }
          nextLinkClassName="page-link"
          activeClassName={"active"}
          marginPagesDisplayed={2}
        />
      </>
    );
  }

  if (data.length === 1)
    return (
      <>
        <Items currentItems={currentItems} />
        <p>
          <br />
        </p>
      </>
    );
  else {
    return <div>Loading</div>;
  }
};

export default PreviousHighlights;
