import React, { useEffect, useState } from "react";
import { HighlightsData } from "../../data/HighlightsData";
import "./highlights.css";
// const HIGHLIGHT_TIME_OUT = 8000;
const HIGHLIGHT_TIME_OUT = 80000;
const Highlights = () => {
  const data = HighlightsData;
  const highlightData = data.slice(0, 5);

  const [current, setCurrent] = useState(0);
  const [highlight, setHighlight] = useState(highlightData[current]);

  useEffect(() => setHighlight(highlightData[current]), [current, highlight]);

  useEffect(() => {
    const newTimerId = setTimeout(() => nextHighlight(), HIGHLIGHT_TIME_OUT);
    return () => clearTimeout(newTimerId);
  }, [current]);

  const nextHighlight = () => {
    current === highlightData.length - 1
      ? setCurrent(0)
      : setCurrent(current + 1);
  };

  const prevHighlight = () => {
    current === 0
      ? setCurrent(highlightData.length - 1)
      : setCurrent(current - 1);
  };

  const dotPicksHighlight = (e) => setCurrent(Number(e.target.id));

  return (
    <div id={"highlights"}>
      <div className="slideshow-container">
        <Slide highlight={highlight} />
        <Arrows nextHighlight={nextHighlight} prevHighlight={prevHighlight} />
      </div>
      <Dots
        dotQty={highlightData}
        current={current}
        dotPicksHighlight={dotPicksHighlight}
      />
    </div>
  );
};

function createMarkup(data) {
  return { __html: data };
}

// TODO: modify CSS
function Slide({ highlight }) {
  const renderImage = highlight.thumbnail ? (
    // <i className={`${highlight.image} fa-3x icon-adjustment`} style={{color: "#778492", alignItems:"center", verticalAlign:"middle", margin:"auto"}}/>
    <a href={highlight.url}>
      <figure className="media-left">
        <p className="image is-64x64 ">
          <img src={highlight.thumbnail} alt={"blog thumbnails"} />
        </p>
      </figure>
    </a>
  ) : null;

  return (
    <div
      className={"card container is-shadowless"}
      style={{ position: "relative", justifyContent: "center", width: "80%" }}
    >
      <article className="media">
        <div className={"level"}>
          <div className={"level-item"}>{renderImage}</div>
          <div
            className={"level-item text-adjustment text-adjustment-highlight"}
          >
            <div className="media-content">
              <div className=" card-content ">
                <p className={"title is-5"} style={{ color: "black" }}>
                  {" "}
                  <div
                    dangerouslySetInnerHTML={createMarkup(highlight.title)}
                  />
                </p>
                <p
                  className="subtitle is-6 text-adjustment text-adjustment-highlight"
                  style={{ color: "#778492", fontWeight: "bold" }}
                >
                  [{highlight.duration}] &nbsp;
                </p>

                {/*description rendering*/}
                <span className={""} style={{ color: "#778492" }}>
                  <div dangerouslySetInnerHTML={createMarkup(highlight.desc)} />
                  <br />
                </span>
              </div>
            </div>
          </div>
        </div>
      </article>
    </div>
  );
}

function Arrows({ nextHighlight, prevHighlight }) {
  return (
    <>
      <a onClick={prevHighlight} className="prev" id="prev">
        &#10094;
      </a>
      <a onClick={nextHighlight} className="next" id="next">
        &#10095;
      </a>
    </>
  );
}

function Dots({ dotQty, current, dotPicksHighlight }) {
  return (
    <div className="dot-container">
      {dotQty.map((dot, i) => {
        return (
          <span
            id={i}
            className={current === i ? "dot active" : "dot"}
            onClick={dotPicksHighlight}
          />
        );
      })}
    </div>
  );
}

export default Highlights;
