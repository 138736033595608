import React, { useState, useEffect } from "react";
import ProfilePic2 from "../../assets/images/Ashmi-2.jpg";
import { ContactData, SocialData } from "../../data/ContactData";
import Socials from "../intro/Socials";

function createMarkup(data) {
  return { __html: data };
}

const HeroCV = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 600);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const containerStyle = {
    display: "flex",
    alignItems: "center",
    maxHeight: "100vh",
    overflowY: "auto",
    padding: "1rem",
    boxSizing: "border-box",
    flexWrap: "wrap",
    flexDirection: isSmallScreen ? "column" : "row",
    justifyContent: isSmallScreen ? "center" : "flex-start",
  };

  const imgStyle = {
    width: "200px",
    height: "200px",
    marginRight: isSmallScreen ? "0" : "3rem",
    marginTop: isSmallScreen ? "3rem" : "0",
    marginBottom: isSmallScreen ? "1rem" : "0",
  };

  const bioStyle = {
    display: "flex",
    flexDirection: "column",
    textAlign: isSmallScreen ? "center" : "left",
    flex: 1,
    marginTop: isSmallScreen ? "-2rem" : "-3rem",
    alignItems: isSmallScreen ? "center" : "flex-start",
  };

  const data = ContactData;
  const socialData = SocialData;

  return (
    <div style={containerStyle}>
      <img src={ProfilePic2} alt={"person"} style={imgStyle} />
      <div style={bioStyle}>
        <p className="is-2 name">
          <div dangerouslySetInnerHTML={createMarkup(data[0].name)} />
        </p>
        <span>
          <div dangerouslySetInnerHTML={createMarkup(data[0].bio)} />
        </span>
        <Socials items={socialData} iconSize={"1x"} />
      </div>
    </div>
  );
};

export default HeroCV;
