import React from "react";
import SectionComponent from "../project-template/SectionComponent";
import { EducationData } from "../../data/EducationData";

const Education = () => {
  const data = EducationData;
  const ROWS_TO_DISPLAY = 2;
  return (
    <div id={"education"} className="content rows">
      <p style={{ fontSize: "1.8rem", fontWeight: "600", color: "#0a478a" }}>
        Education
      </p>
      <SectionComponent
        data={data}
        count={ROWS_TO_DISPLAY}
        sectionTitle="Education"
      />
    </div>
  );
};
export default Education;
