import React from "react";
import ToText from "../../utils/toText";
import "./projectTemplate.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";

function createMarkup(data) {
  return { __html: data };
}

const MediaObjectComponent = (props) => {
  const renderBlogThumbnail =
    props.thumbnail && props.activityType === "blog" ? (
      <a href={props.url}>
        <figure className="media-left">
          <i
            className={"fab fa-medium fa-3x "}
            style={{
              color: "#FDB414",
              alignItems: "center",
              verticalAlign: "middle",
            }}
          />
        </figure>
      </a>
    ) : null;
  const renderThumbnail =
    props.thumbnail && props.activityType !== "blog" ? (
      <a href={props.url}>
        <figure className="media-left">
          <p className="image is-64x64 ">
            <img src={props.thumbnail} alt={"blog thumbnails"} />
          </p>
        </figure>
      </a>
    ) : null;
  const renderTitle = props.title ? (
    <>
      <a href={props.url} className={"link-reverse"}>
        <strong style={{ fontSize: "larger" }}>{props.title}</strong>
      </a>
      <br />
    </>
  ) : null;

  const renderBlogDesc = props.blogDescription ? (
    <>
      <span style={{ color: "#778492", overflow: "hidden" }}>
        {`${ToText(props.blogDescription.substring(0, 2000))}..`}
      </span>
    </>
  ) : null;

  const renderDesc = props.description ? (
    <span style={{ color: "#778492" }}>
      <div dangerouslySetInnerHTML={createMarkup(props.description)} />
    </span>
  ) : null;

  const renderTags = props.links
    ? props.links.map((link) => (
        <>
          <a href={link.url} style={{ color: "white", fontSize: "small" }}>
            <span className={`tag ${link.tagColor} is-normal`}>
              <i className={`${link.icon} fa-1x`} style={{ color: "white" }} />
              &nbsp;&nbsp;
              {link.text}
            </span>{" "}
            &nbsp;&nbsp;
          </a>
        </>
      ))
    : null;
  const renderTagsBlogs = props.categories
    ? props.categories.map((category) => (
        <>
          <span className={`tag is-success is-light is-normal`}>
            {category}
          </span>{" "}
          &nbsp;&nbsp;
        </>
      ))
    : null;

  const renderAuthorInfo = props.author ? (
    <>
      <div className={"level is-mobile"}>
        <div className="level-left ">
          <span className="level-item icon is-small " style={{ color: "grey" }}>
            <i className="fas fa-user" />
          </span>
          <a
            className="level-item"
            style={{ color: "grey" }}
            href={"https://medium.com/@ashmi_banerjee"}
          >
            <small>{props.author}</small>
          </a>

          <a className="level-item" style={{ color: "grey" }}>
            <span className="icon is-small">
              <i className="fas fa-calendar" />
            </span>
          </a>
          <p className="level-item" style={{ color: "grey" }}>
            <small>{props.date}</small>
          </p>
        </div>
      </div>
    </>
  ) : null;
  const renderDate =
    props.date && props.activityType !== "blog" ? (
      <div className={"level is-mobile"}>
        <div className="level-left">
          <a className="level-item" style={{ color: "grey" }}>
            <span className="icon is-small">
              <i className="fas fa-calendar" />
            </span>
          </a>
          <p className="level-item" style={{ color: "grey" }}>
            <small>{props.date}</small>
          </p>
        </div>
      </div>
    ) : null;

  const renderReadMoreButton =
    props.activityType === "blog" ? (
      <a href={props.url} style={{ color: "#FFFFFF" }}>
        <button type="submit" className="button is-info btn-download ">
          Read More &nbsp;&nbsp;
          <FontAwesomeIcon
            icon={faAngleDoubleRight}
            size={"1x"}
            style={{ color: "#FFFFFF" }}
          />
        </button>
      </a>
    ) : null;
  return (
    <>
      <article className="media" style={{ overflow: "hidden" }}>
        {renderBlogThumbnail}
        {renderThumbnail}
        <div
          className="media-content media-adjustment"
          style={{ overflow: "hidden" }}
        >
          <div className="content" style={{ overflow: "hidden" }}>
            <p>
              {renderTitle}
              {renderBlogDesc}
              {renderDesc}
            </p>
          </div>
          {renderTags}
          {renderTagsBlogs}
          <p>
            <br />
          </p>
          {renderAuthorInfo}
          {renderReadMoreButton}
          {renderDate}
          {/*  <p><br/></p>*/}
        </div>
      </article>
    </>
  );
};
export default MediaObjectComponent;
