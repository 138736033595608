import ProfilePic2 from "../assets/images/ashmi 2.jpg"
import { faBlog, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faGithub,
  faLinkedin,
  faTwitterSquare,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

export const ContactData = [
  {
    name: '<span style="font-weight: bold; font-size: x-large; color: #545e69; text-align: center">Ashmi Banerjee</span> (She/Her)',
    image: ProfilePic2,
    smallText: "Msc. (Informatics)",
    bio:
      '<p style="color:#0a478a;text-align: left;">PhD Candidate @ TU' +
      ' Munich</p> ' +
      '<p style="color:#34A853;text-align: left;">ML GDE <span' +
      ' style="color:black;text-align: left;"> | </span>' + 'Google WTMA' +
      ' </p>' ,
      // +
      // '<p style="color:#4285F4;text-align: center;">Google Women Techmakers Ambassador</p>',
  },
];

library.add(
  faEnvelope,
  faBlog,
  faGithub,
  faTwitterSquare,
  faLinkedin,
  faTwitter,
);

export const SocialData = [
  {
    id: "email",
    title: "Email",
    href: "mailto:ashmi.banerjee@tum.de",
    icon: ["fa", "envelope"],
    color: "#3e8ed0ff",
  },
  {
    id: "linkedIn",
    title: "LinkedIn",
    href: "https://www.linkedin.com/in/ashmi-banerjee/",
    icon: ["fab", "linkedin"],
  },
  {
    id: "gitHub",
    title: "GitHub",
    href: "https://github.com/ashmibanerjee",
    icon: ["fab", "github"],
    color: "#ea4f4b",
  },
  {
    id: "twitter",
    title: "Twitter",
    href: "https://twitter.com/ashmi_banerjee",
    icon: ["fab", "twitter"],
  },
  {
    id: "medium",
    title: "Medium",
    href: "https://medium.com/@ashmi_banerjee",
    icon: ["fa", "blog"],
    color: "#34A853",
  },
];
