import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Talks from "../talks/Talks";
import Blogs from "../blogs/Blogs";
import FeaturedComponent from "../featured/FeaturedComponent";
import Teaching from "../teach/Teach";

const Activities = () => {
  const [activeTab, setActiveTab] = useState("tab1");
  const location = useLocation();
  const hash = location.hash;
  const navigate = useNavigate();

  const handleTab1 = () => {
    // update the state to tab1
    setActiveTab("tab1");
    navigate("/#speak");
  };
  const handleTab2 = () => {
    // update the state to tab2
    setActiveTab("tab2");
    navigate("/#teach");
  };
  const handleTab3 = () => {
    // update the state to tab2
    setActiveTab("tab3");
    navigate("/#blogs");
  };
  useEffect(() => {
    switch (hash) {
      case "#speak":
        setActiveTab("tab1");
        break;
      // case "#teach":
      //   setActiveTab("tab2");
      //   break;
      case "#blogs":
        setActiveTab("tab3");
        break;
    }
  }, [hash, setActiveTab]);

  return (
    <div id={"activities"}>
      <article className="is-light" style={{ background: "white" }}>
        <p
          className="title"
          style={{ color: "#0a478a", fontSize: "2.0rem", fontWeight: "600" }}
        >
          Activities
        </p>
        <div className="tabs  is-fullwidth is-boxed">
          <ul>
            <li
              className={activeTab === "tab1" ? "is-active" : ""}
              onClick={handleTab1}
            >
              <a>
                <span
                  className={
                    "icon is-small " + (activeTab === "tab1" ? "is-active" : "")
                  }
                >
                  <i className="fas fa-microphone-alt" aria-hidden="true"></i>
                </span>
                <span>Speak</span>
              </a>
            </li>
            {/*<li*/}
            {/*  className={activeTab === "tab2" ? "is-active" : ""}*/}
            {/*  onClick={handleTab2}*/}
            {/*>*/}
            {/*  <a>*/}
            {/*    <span*/}
            {/*      className={*/}
            {/*        "icon is-small " + (activeTab === "tab2" ? "is-active" : "")*/}
            {/*      }*/}
            {/*      onClick={handleTab2}*/}
            {/*    >*/}
            {/*      <i*/}
            {/*        className="fas fa-chalkboard-teacher"*/}
            {/*        aria-hidden="true"*/}
            {/*      ></i>*/}
            {/*    </span>*/}
            {/*    <span>Teach</span>*/}
            {/*  </a>*/}
            {/*</li>*/}
            <li
              className={activeTab === "tab3" ? "is-active" : ""}
              onClick={handleTab3}
            >
              <a>
                <span
                  className={
                    "icon is-small " + (activeTab === "tab3" ? "is-active" : "")
                  }
                  onClick={handleTab3}
                >
                  <i className="fas fa-pen-alt" aria-hidden="true"></i>
                </span>
                <span>Blogs</span>
              </a>
            </li>
          </ul>
        </div>
        {(() => {
          if (activeTab === "tab1") {
            return <Talks />;
          } else if (activeTab === "tab2") {
            return <Teaching />;
          } else if (activeTab === "tab3") {
            return <Blogs />;
          } else {
            return <FeaturedComponent />;
          }
        })()}
      </article>
    </div>
  );
};

export default Activities;
