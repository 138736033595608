import React, { useState } from "react";
import Publication from "./Publication";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { PublicationsData } from "../../data/PublicationsData";

const Publications = () => {
  const data = PublicationsData;
  const ROWS_TO_DISPLAY = 3;
  const [itemsToShow, setItemsToShow] = useState(ROWS_TO_DISPLAY);

  const showMore = () => {
    setItemsToShow(data.length);
  };

  const showLess = () => {
    setItemsToShow(ROWS_TO_DISPLAY);
  };

  return (
    <div id={"publications"} className="content rows">
      <p style={{ fontSize: "1.8rem", fontWeight: "600", color: "#0a478a" }}>
        Publications
      </p>
      <div className="publications-text">
        {data.slice(0, itemsToShow).map((item) => (
          <Publication
            authorNames={item.authorNames}
            paperTitle={item.paperTitle}
            venue={item.venue}
            paperUrl={item.paperUrl}
            bibTeX={item.bibTeX}
          />
        ))}
        <span>
          <br />
          <FontAwesomeIcon
            icon={faExternalLinkAlt}
            size={"1x"}
            style={{ color: "#778492" }}
          />{" "}
          &nbsp;
          <a
            href={
              "https://scholar.google.com/citations?user=ygXK024AAAAJ&hl=en"
            }
            style={{ color: "black", fontSize: "small" }}
          >
            Google Scholar Profile
          </a>
        </span>
        {itemsToShow === ROWS_TO_DISPLAY ? (
          <button
            className="button  is-primary is-light is-centered is-fullwidth show-more-button is-small"
            onClick={showMore}
            type="submit"
          >
            Show all {data.length} Publications
          </button>
        ) : (
          <button
            className="button is-light is-centered is-fullwidth is-small"
            onClick={showLess}
          >
            Show Less
          </button>
        )}
      </div>
    </div>
  );
};

export default Publications;
