import Coursera from "../assets/images/coursera.jpeg";
import Goethe from "../assets/images/goethe.png";
import Google from "../assets/images/google.jpeg";
import B1 from "../assets/documents/B1_Certificate.pdf";
import B2 from "../assets/documents/Goethe B2.pdf";
import A2 from "../assets/documents/A2_Certificate.pdf";
import A1 from "../assets/documents/A1.pdf";
import WDA from "../assets/documents/WDA_22_Certificate.pdf";
import WTMMentorDev from "../assets/documents/WTM_MentorDev22 .jpeg";
export const CertificationsData = [
  {
    image: Google,
    title: "Google",
    items: [
      {
        name: "Women Techmakers Mentor Development Program",
        issueDate: "October 2022",
        issuedBy: "Women Techmakers",
        url: WTMMentorDev,
      },
      {
        name: "Women Developer Academy",
        issueDate: "March 2022",
        issuedBy: "Google Developer Relations",
        url: WDA,
      },
    ],
  },
  {
    image: Coursera,
    title: "Coursera",
    items: [
      {
        name: "Advanced Learning Algorithms",
        issueDate: "October 2022",
        issuedBy: "DeepLearning.AI",
        credentialID: "3T3DTMDCV9TJ",
        url: "https://www.coursera.org/account/accomplishments/certificate/3T3DTMDCV9TJ",
      },
      {
        name: "Supervised Machine Learning: Regression and Classification",
        issueDate: "October 2022",
        issuedBy: "DeepLearning.AI",
        credentialID: "6XJTDNFCQG46",
        url: "https://www.coursera.org/account/accomplishments/certificate/6XJTDNFCQG46",
      },
      {
        name: "Neural Networks and Deep Learning",
        issueDate: "September 2022",
        issuedBy: "DeepLearning.AI",
        credentialID: "X52DWELCCWXQ",
        url: "https://www.coursera.org/account/accomplishments/certificate/X52DWELCCWXQ",
      },
      {
        name: "Deploying Machine Learning Models in Production",
        issueDate: "May 2022",
        issuedBy: "DeepLearning.AI",
        credentialID: "TSAHJ4WNKJ8B",
        url: "https://www.coursera.org/account/accomplishments/certificate/TSAHJ4WNKJ8B",
      },
      {
        name: "Introduction to Big Data with Spark and Hadoop",
        issueDate: "March 2022",
        issuedBy: "IBM",
        credentialID: "3RRU6Q6SRJ5A",
        url: "https://www.coursera.org/account/accomplishments/certificate/3RRU6Q6SRJ5A",
      },
      {
        name: "Introduction to Machine Learning in Production",
        issueDate: "February 2022",
        issuedBy: "DeepLearning.AI",
        credentialID: "5LN3D7XFM265",
        url: "https://www.coursera.org/account/accomplishments/certificate/5LN3D7XFM265",
      },
      {
        name: "Introduction to Applied Machine Learning",
        issueDate: "February 2022",
        issuedBy: "Alberta Machine Intelligence Institute",
        credentialID: "\n" + "S2N3WF9CGDMF",
        url:
          "https://www.coursera.org/account/accomplishments/certificate/\n" +
          "S2N3WF9CGDMF",
      },
      {
        name: "AI for Everyone",
        issueDate: "February 2022",
        issuedBy: "DeepLearning.AI",
        credentialID: "\n" + "Y7P5GGH67Y2Q",
        url:
          "https://www.coursera.org/account/accomplishments/certificate/\n" +
          "Y7P5GGH67Y2Q",
      },
      {
        name: "Introduction to Data Engineering",
        issueDate: "August 2021",
        issuedBy: "IBM",
        credentialID: "RMZPDRGASDDT",
        url: "https://www.coursera.org/account/accomplishments/certificate/RMZPDRGASDDT",
      },
      {
        name: "Python for Data Science, AI & Development",
        issueDate: "August 2021",
        issuedBy: "IBM",
        credentialID: "WJRKEAP8PJM3",
        url: "https://www.coursera.org/account/accomplishments/certificate/WJRKEAP8PJM3",
      },

      {
        name: "Agile Planning for Software Products",
        issueDate: "December 2020",
        issuedBy: "University of Alberta",
        credentialID: "BBNXXBEV2LEZ",
        url: "https://www.coursera.org/account/accomplishments/certificate/BBNXXBEV2LEZ",
      },
      {
        name: "Introduction to Software Product Management",
        issueDate: "November 2020",
        issuedBy: "University of Alberta",
        credentialID: "WKW3DHXEXD4B",
        url: "https://www.coursera.org/account/accomplishments/certificate/WKW3DHXEXD4B",
      },
      {
        name: "Software Processes & Agile Practices",
        issueDate: "November 2020",
        issuedBy: "University of Alberta",
        credentialID: "DXH2EBN42MS2",
        url: "https://www.coursera.org/account/accomplishments/certificate/DXH2EBN42MS2",
      },
    ],
  },
  {
    image: Goethe,
    title: "Goethe Institut e.V ",
    items: [
      {
        name: "Goethe Zertifikat B2 (Intermediate User, Level 2)",
        issueDate: "March 2022",
        url: B2,
      },
      {
        name: "Goethe Zertifikat B1 (Intermediate User, Level 1)",
        issueDate: "June 2021",
        url: B1,
      },
      {
        name: "Goethe Zertifikat A2 : Start Deutsch 2 (Basic User, Level 2)",
        issueDate: "May 2016",
        url: A2,
      },
      {
        name: "Goethe Zertifikat A1 : Start Deutsch 1 (Basic User, Level 1)",
        issueDate: "May 2015",
        url: A1,
      },
    ],
  },
];
