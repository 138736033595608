import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import MediaObjectComponent from "./MediaObjectComponent";
import "./paginate.css";
function Items({ currentItems }) {
  console.log("current items", currentItems);
  return (
    <>
      {currentItems.map((item) => {
        if (item.activityType === "speak" || item.activityType === "teach" || item.activityType === "podcast")
          return (
            <MediaObjectComponent
              title={item.organisation}
              description={item.desc}
              date={item.duration}
              links={item.links}
              thumbnail={item.thumbnail}
              url={item.url}
            />
          );
        if (item.activityType === "blog")
          return (
            <MediaObjectComponent
              activityType="blog"
              title={item.title}
              author={item.author}
              thumbnail={item.thumbnail}
              blogDescription={item.content}
              date={item.pubDate.substring(0, 10)}
              categories={item.categories}
              url={item.link}
            />
          );
        if (item.activityType === "featured")
          return (
            <MediaObjectComponent
              title={item.organisation}
              description={item.desc}
              thumbnail={item.thumbnail}
              date={item.duration}
              links={item.links}
              url={item.url}
            />
          );
        else return <div>Test</div>;
      })}
    </>
  );
}

const PaginateComponent = (props) => {
  const data = props.data;

  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState(data);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + props.itemsPerPage;
    // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(data.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / props.itemsPerPage));
  }, [itemOffset, props.itemsPerPage]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * props.itemsPerPage) % data.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`,
    );
    console.log(`length of items ${data.length}`);
    setItemOffset(newOffset);
  };
  console.log(itemOffset);

  if (data.length > 1)
    return (
      <>
        <Items currentItems={currentItems} />
        <p>
          <br />
        </p>
        <ReactPaginate
          breakLabel="..."
          nextLabel={
            itemOffset + props.itemsPerPage >= data.length ? (
              <div
                style={{
                  border: "1px solid" + " white",
                  backgroundColor: "red",
                }}
              >
                h
              </div>
            ) : (
              <>&raquo;</>
            )
          }
          onPageChange={handlePageClick}
          pageRangeDisplayed={2}
          pageCount={pageCount}
          previousLabel={
            itemOffset === 0 ? (
              <div
                style={{ border: "1px solid white", backgroundColor: "red" }}
              >
                h
              </div>
            ) : (
              <>&laquo;</>
            )
          }
          renderOnZeroPageCount={null}
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination justify-content-center"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName={itemOffset === 0 ? "hide-label" : "page-item"}
          previousLinkClassName="page-link"
          nextClassName={
            itemOffset + props.itemsPerPage >= data.length
              ? "hide-label"
              : "page-item"
          }
          nextLinkClassName="page-link"
          activeClassName={"active"}
          marginPagesDisplayed={2}
        />
      </>
    );
  if (data.length === 1)
    return (
      <>
        <Items currentItems={currentItems} />
        <p>
          <br />
        </p>
      </>
    );
  else {
    return <div>Loading</div>;
  }
};
export default PaginateComponent;
