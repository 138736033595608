import React from "react";
import PaginateComponent from "../project-template/PaginateComponent";
import { TeachingData } from "../../data/TeachingData";

const itemsPerPage = 2;
const Teaching = () => {
  const data = TeachingData;
  const teachData = data.filter((d) => d.activityType === "teach");

  return (
    <>
      <div id={"teach"} style={{ paddingTop: "1.0rem" }}>
        <p
          style={{
            fontSize: "1.8rem",
            fontWeight: "600",
            paddingBottom: "2.0rem",
            color: "#545e69",
          }}
        >
          Teaching Responsibilities
        </p>
        <PaginateComponent data={teachData} itemsPerPage={itemsPerPage} />
      </div>
    </>
  );
};
export default Teaching;
