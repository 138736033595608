export const HeaderData = [
  // {url: "/", text: "Home"},

  { url: "/#about-me", text: "About Me" },
  {
    url: "/#activities",
    text: "Activities",
    dropDown: [
      {
        text: "Speak",
        url: "#speak",
      },
      // {
      //   text: "Teach",
      //   url: "#teach",
      // },
      {
        text: "Blogs",
        url: "#blogs",
      },
    ],
  },
  { url: "/#publications", text: "Publications" },
  {
    text: "CV",
    url: "/cv",
  },

];
