import React from "react";
import "./languages.css";

const Languages = () => {
  return (
    <div id={"languages"} className="content">
      {/*<div className="languages">*/}
      <p
        style={{
          fontSize: "1.8rem",
          fontWeight: "600",
          color: "#545e69",
          paddingTop: "2.0rem",
        }}
      >
        Languages
      </p>
      <div className="rows content">
        <div className="card row item m-2 is-shadowless">
          <p className="level-title">
            <b className="level-key">Bengali</b>&nbsp;
            <div className="level-value">Native &nbsp;</div>
            <div className="star">
              <i className="fa fa-star" />
              <i className="fa fa-star" />
              <i className="fa fa-star" />
              <i className="fa fa-star" />
              <i className="fa fa-star" />
            </div>
          </p>
        </div>
        <div className="card row item m-2 is-shadowless">
          <p className="level-title">
            <b className="level-key">English</b>&nbsp;
            <div className="level-value">
              Full professional proficiency &nbsp;
            </div>
            <div className="star">
              <i className="fa fa-star" />
              <i className="fa fa-star" />
              <i className="fa fa-star" />
              <i className="fa fa-star" />
              <i className="fa fa-star-half" />
            </div>
          </p>
        </div>
        <div className="card row item m-2 is-shadowless">
          <p className="level-title">
            <b className="level-key">Hindi</b>&nbsp;
            <div className="level-value">
              Full professional proficiency &nbsp;
            </div>
            <div className="star">
              <i className="fa fa-star" />
              <i className="fa fa-star" />
              <i className="fa fa-star" />
              <i className="fa fa-star" />
              <i className="fa fa-star-half" />
            </div>
          </p>
        </div>
        <div className="card row item m-2 is-shadowless">
          <p className="level-title">
            <b className="level-key">German</b>&nbsp;
            <div className="level-value">
              Intermediate working proficiency (B2) &nbsp;
            </div>
            <div className="star">
              <i className="fa fa-star" />
              <i className="fa fa-star" />
              <i className="fa fa-star" />
              <i className="fa fa-star-half" />
            </div>
          </p>
        </div>
      </div>
      <div className="columns content"></div>

      {/*</div>*/}
    </div>
  );
};
export default Languages;
