import React, { useState } from "react";
import "./projectTemplate.css";

function createMarkup(data) {
  return { __html: data };
}

const ProjectTemplate = (props) => {
  const diamondSymbol = ` \u25C6 `;

  const [showDesc, setShowDesc] = useState(false);
  const onClickDown = () => setShowDesc(true);
  const onClickUp = () => setShowDesc(false);

  // Important for multi-position entries where it should not start with a diamond symbol
  const firstLine = props.company[0].name ? (
    <span>
      <a href={props.company[0].url}>{props.company[0].name}</a>
      {diamondSymbol}
    </span>
  ) : null;

  // when there are multiple positions within the same company //

  const renderMultiPositions = props.multiPositions ? (
    <div>
      {props.multiPositions.map((position) => (
        <article className="media">
          <div className="content" style={{ overflow: "hidden" }}>
            <strong style={{ color: "#545e69" }}>{position.name} </strong>
            <span style={{ color: "#ea4f4b" }}>
              <a href={props.company[0].url}>{position.company}</a>{" "}
            </span>
            <br />
            <span style={{ color: "rgb(84, 94, 105)" }}>
              {position.duration}
            </span>
            <br />
            <p style={{ marginTop: "0.8rem", color: "#778492" }}>
              {position.desc
                ? position.desc.map((item) => (
                    <span style={{ fontSize: "small" }}>
                      {item.para}
                      <br />
                    </span>
                  ))
                : null}
            </p>
          </div>
        </article>
      ))}
    </div>
  ) : null;

  const renderImage = props.image ? (
    <p className="image is-64x64" style={{ marginTop: "1.5rem" }}>
      <img src={props.image} alt={"media"} />
    </p>
  ) : null;

  const renderSubheading = (
    <p className="subtitle is-6 text-adjustment">
      {props.duration ? (
        <span>
          {props.duration} <br />{" "}
        </span>
      ) : null}

      {props.company[0].employmentType ? (
        <p style={{ color: "black" }} className="text-adjustment">
          {firstLine}
          {props.company[0].employmentType}
          {diamondSymbol}
          <i className="text-adjustment">{props.company[0].location}</i>
        </p>
      ) : (
        <p style={{ color: "black" }}>
          {firstLine}
          <i>{props.company[0].location}</i>
        </p>
      )}
    </p>
  );

  const renderDescription = props.desc ? (
    <p style={{ marginTop: "0.8rem" }}>
      {props.desc
        ? props.desc.map((item) => (
            <span
              class="text-adjustment"
              style={{ fontSize: "small", color: "#778492" }}
            >
              <div dangerouslySetInnerHTML={createMarkup(item.para)} />
              {/*<br />*/}
            </span>
          ))
        : null}
    </p>
  ) : null;

  const renderOpenClickComponent = showDesc ? (
    <>
      {renderMultiPositions}
      {renderDescription}
    </>
  ) : null;

  const generateAwardLink = props.company[0].url ? (
    <a href={props.company[0].url} className={"link-reverse"} style={{textDecoration:"underline"}}>
    <div dangerouslySetInnerHTML={createMarkup(props.title)} />
  </a>

    ) :
    (<div dangerouslySetInnerHTML={createMarkup(props.title)} />)

  return (
    <div className="card row m-2 is-shadowless ">
      <article className="media" style={{ overflow: "hidden" }}>
        {renderImage}
        <div className="media-content" style={{ overflow: "hidden" }}>
          <div className=" card-content ">

            <h4 className={"text-adjustment"}>
              {generateAwardLink}
            </h4>
            {renderSubheading}
            {renderOpenClickComponent}
          </div>
        </div>
        {showDesc ? (
          <a
            className="card-header-icon card-toggle is-right"
            onClick={onClickUp}
          >
            <i className="fa fa-angle-up fa-1x" />
          </a>
        ) : (
          <a className="card-header-icon card-toggle" onClick={onClickDown}>
            <i className="fa fa-angle-down" />
          </a>
        )}
      </article>
    </div>
  );
};
export default ProjectTemplate;
